import { addMethod, string, StringSchema } from 'yup'
import 'yup-phone'

addMethod<StringSchema>(
  string,
  'validatePhone',
  function (countryCode?: string, strict?: boolean, errorMessage?: string) {
    const error = errorMessage || 'Must be a valid phone number'
    return this.test('test-phone', error, value => {
      if (value) return this.phone(countryCode, strict, error).isValidSync(value)
      return true
    })
  },
)

addMethod<StringSchema>(string, 'validatePhoneGhana', function (errorMessage?: string) {
  const error = errorMessage || 'Phone number must be valid for the region of Ghana'
  return this.test('test-phone', error, value => {
    if (value) return this.phone('GH', true, error).isValidSync(value)
    return true
  })
})
